/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 07:34:15
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-21 21:21:04
 * @FilePath: /site/src/api/guest/modules/Captcha.js
 */

import { Axios } from "@/plugins/AxiosPlugin";

export const getCaptcha = (params) =>
  Axios.get("/api/guest/captcha/", {
    params: params,
  });
