<template>
  <section class="section">
    <div class="container text-center mt-50">
      <router-link to="/">
        <img src="../assets/images/logo.png" width="500"/>
      </router-link>

      <el-card
        shadow="hover"
        class="login mt-20"
        :body-style="{ padding: '20px' }"
      >
        <el-tabs v-model="activeName">
          <el-tab-pane label="账号登录" name="login">
            <el-form
              :model="form"
              ref="loginForm"
              class="mt-20"
              :rules="rules"
              label-width="80px"
              :inline="false"
            >
              <el-form-item label="账号" prop="account">
                <el-input
                  v-model="form.account"
                  placeholder="请输入email"
                  @keyup.enter.native="signInSubmit"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  v-model="form.password"
                  type="password"
                  placeholder="请输入密码"
                  @keyup.enter.native="signInSubmit"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="captcha">
                <el-input
                  v-model="form.captcha"
                  placeholder="请输入验证码"
                  @keyup.enter.native="signInSubmit"
                >
                  <template slot="append">
                    <el-button type="primary" @click="getCaptcha">
                      <span v-html="captcha"></span>
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="signInSubmit"
                  >立即登录</el-button
                >
                <el-button @click="back">取消</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="创建账号" name="signup">
            <el-form
              :model="form"
              ref="sginUpForm"
              class="mt-20"
              :rules="rules"
              label-width="80px"
              :inline="false"
            >
              <el-form-item label="昵称" prop="nickname">
                <el-input
                  v-model="form.nickname"
                  placeholder="请输入昵称"
                  autocomplete="off"
                  @keyup.enter.native="signUpSubmit"
                ></el-input>
              </el-form-item>
              <el-form-item label="账号" prop="account">
                <el-input
                  v-model="form.account"
                  placeholder="请输入email"
                  autocomplete="off"
                  @keyup.enter.native="signUpSubmit"
                ></el-input>
              </el-form-item>

              <el-form-item label="密码" prop="password">
                <el-input
                  v-model="form.password"
                  type="password"
                  placeholder="请输入密码"
                  autocomplete="off"
                  @keyup.enter.native="signUpSubmit"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="repassword">
                <el-input
                  v-model="form.repassword"
                  type="password"
                  placeholder="请输入确认密码"
                  @keyup.enter.native="signUpSubmit"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="captcha">
                <el-input
                  v-model="form.captcha"
                  placeholder="请输入验证码"
                  @keyup.enter.native="signUpSubmit"
                  autocomplete="off"
                >
                  <template slot="append">
                    <el-button type="primary" @click="getCaptcha">
                      <span v-html="captcha"></span>
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="signUpSubmit"
                  >立即创建</el-button
                >
                <el-button @click="back">取消</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="找回密码" name="find">
            <el-form
              :model="form"
              ref="findForm"
              class="mt-20"
              :rules="rules"
              label-width="80px"
              :inline="false"
            >
              <el-form-item label="账号" prop="account">
                <el-input
                  v-model="form.account"
                  placeholder="请输入email"
                  @keyup.enter.native="findSubmit"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="captcha">
                <el-input
                  v-model="form.captcha"
                  placeholder="请输入验证码"
                  @keyup.enter.native="findSubmit"
                >
                  <template slot="append">
                    <el-button type="primary" @click="getCaptcha">
                      <span v-html="captcha"></span>
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="findSubmit"
                  >找回密码</el-button
                >
                <el-button @click="back">取消</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </section>
</template>

<script>
import API_guest from "@/api/guest";
export default {
  name: "login",
  data() {
    return {
      captcha: null,
      activeName: "login",
      form: {
        nickname: "",
        account: "",
        password: "",
        repassword: "",
        captcha: "",
        captchaToken: "",
      },
      rules: {
        nickname: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (this.$utils.isEmpty(value) || this.$utils.len(value) < 2) {
                callback(new Error("昵称必须大于2个字符"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          // {
          //   validator: (rule, value, callback) => {
          //     if (this.$utils.isEmpty(value) || !this.$utils.isEmpty(value)) {
          //       callback(new Error("账号必须是邮箱"));
          //     } else {
          //       callback();
          //     }
          //   },
          //   trigger: "blur",
          // },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入密码"));
              } else if (this.$utils.checkPasswordStrong(value) < 2) {
                callback(new Error("密码必须5位以上，且必须包含两种字符以上"));
              } else {
                if (
                  this.form.repassword !== "" &&
                  this.activeName == "signup"
                ) {
                  this.$refs.sginUpForm.validateField("repassword");
                }
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        repassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.form.password) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        captcha: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  metaInfo() {
    return {
      title: "登录-" + this.Settings.base.name,
    };
  },
  mounted() {
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab;
    }
    this.getCaptcha();
  },
  methods: {
    signInSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.$api.Auth.login(this.form)
          .then((res) => {
            console.log("res:", res);
            this.getCaptcha();
            this.$store.dispatch("setUser", res.data);
            this.redirect();
          })
          .catch((e) => {
            this.getCaptcha();
          });
      });
    },
    signUpSubmit() {
      this.$refs.sginUpForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.$api.Auth.create(this.form)
          .then((res) => {
            this.getCaptcha();
            this.$store.dispatch("setUser", res.data);
            this.redirect();
          })
          .catch((e) => {
            this.getCaptcha();
          });
      });
    },
    redirect() {
      let redirect = this.$route.query.redirect || "/";
      if (redirect.includes("login")) {
        redirect = "/";
      }
      this.$router.push({
        path: redirect,
      });
    },
    findSubmit() {
      this.$refs.findForm.validate((valid) => {
        if (!valid) {
          return false;
        }
      });
    },
    getCaptcha() {
      API_guest.Captcha.getCaptcha().then((res) => {
        this.form.captchaToken = res.data.captchaToken;
        this.captcha = res.data.captcha;
      });
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
</style>