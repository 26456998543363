/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-01 08:37:43
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-08-04 08:46:22
 * @FilePath: /site/src/plugins/AxiosPlugin.js
 */

import Vue from "vue";
import _Axios from "@/plugins/Axios";
import utils from "./utils";
const _option = {};

_option.error = (res) => {
  if (typeof res == "string") {
    utils.error(res);
    return;
  }
  if (res.code !== 0) {
    utils.error(res.msg);
  }
};

Plugin.install = (Vue, options) => {
  options = Object.assign({}, _option, options);

  Vue.axios = _Axios(options);
  window.axios = _Axios(options);
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _Axios(options);
      },
    },
    $axios: {
      get() {
        return _Axios(options);
      },
    },
  });
};

Vue.use(Plugin);
export const Axios = _Axios(_option);
export default Plugin;
